import { css } from 'styled-components';

import {
  rem, media, offsetCol, maxCol,
} from '../../../helpers/mixins';
import { WHITE, BLACK } from '../../../constants/colors';

export default {
  callToAction: ({ gradientEnd, gredientStart, idName }) => css`
    display: flex;
    font-size: ${rem('12px')};
    font-weight: bold;
    margin-top: 50px;
    padding: 9px 32px 7px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: fit-content;
    z-index: 2;

    &:hover svg rect {
      fill: url(#${idName});

      ${gradientEnd === WHITE && gredientStart === WHITE && css`
        opacity: 0.4;
      `}
    }

    ${media.md`
      font-size: ${rem('13px')};
    `}
  `,
  content: ({ columns, offset }) => css`
    color: ${WHITE};
    font-size: ${rem('16px')};
    line-height: ${rem('24px')};
    margin-top: 34px;

    ${media.md`
      ${offsetCol(offset)};
      ${maxCol(columns)}
      margin-top: 16px;
    `}
  `,
  contentWrapper: css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  `,
  mainContent: css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    margin-top: 20vh;
    padding-bottom: 80px;

    ${media.md`
      justify-content: center;
      margin-top: 0;
      padding-bottom: 0;
    `}
  `,
  placeholderDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  placeholderMobile: css`
    display: block;

    ${media.md`
      display: none;
    `}
  `,
  svgRect: ({ idName }) => css`
    fill: transparent;
    height: calc(100% - 1px);
    stroke: url(#${idName});
    stroke-width: 1;
    width: calc(100% - 1px);
  `,
  svgWrapper: css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  `,
  title: ({ columns, offset }) => css`
    color: ${WHITE};
    font-size: ${rem('24px')};
    line-height: ${rem('30px')};

    ${media.md`
      ${offsetCol(offset)};
      ${maxCol(columns)}
      font-size: ${rem('56px')};
      line-height: ${rem('76px')};
    `}
  `,
  wrapper: css`
    background-color: ${BLACK};
    height: 120vh;
    position: relative;
    width: 100vw;

    ${media.md`
      height: 100vh;
    `}
  `
};
