import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { setActiveSlide } from '../../../store/actions';
import { useIsInView } from '../../../helpers/hooks';
import Text from '../../base/Text';
import ImageFluidWrapper from '../../base/ImageFluidWrapper';
import ImageFluid from '../../base/ImageFluid';
import Link from '../../base/Link';
import { getKey, isValidObject } from '../../../helpers';
import { WHITE } from '../../../constants/colors';
import Styles from './ContentCard.styles';

const CallToAction = styled(
  ({
    gradientEnd,
    gredientStart,
    idName,
    ...otherProps
  }) => <Link {...otherProps} />
)`${Styles.callToAction}`; // prettier-ignore
const Content = styled(Text)`${Styles.content}`; // prettier-ignore
const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const MainContent = styled.div`${Styles.mainContent}`; // prettier-ignore
const SvgRect = styled.rect`${Styles.svgRect}`; // prettier-ignore
const SvgWrapper = styled.svg`${Styles.svgWrapper}`; // prettier-ignore
const Title = styled(Text)`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore
const PlaceholderDesktop = styled(ImageFluid)`${Styles.placeholderDesktop}`; // prettier-ignore
const PlaceholderMobile = styled(ImageFluid)`${Styles.placeholderMobile}`; // prettier-ignore

/**
 * ContentCard component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const ContentCard = ({
  backgroundImage,
  backgroundImageMobile,
  content,
  contentSettings,
  gradient,
  index,
  link,
  title,
  titleSettings
}) => {
  const dispatch = useDispatch();
  const [ref, isIntersecting] = useIsInView();

  if (isIntersecting) {
    dispatch(setActiveSlide(index));
  }

  const placeholderMobile = isValidObject(backgroundImageMobile)
    ? backgroundImageMobile : backgroundImage;

  const callToAction = useMemo(() => {
    const callToActionTitle = getKey(link, 'title');
    const callToActionUri = getKey(link, 'uri');
    const gradientId = getKey(gradient, 'id');
    const gredientStart = getKey(gradient, 'start', WHITE);
    const gradientEnd = getKey(gradient, 'end', WHITE);

    if (!callToActionTitle || !callToActionUri) return null;

    return (
      <CallToAction
        href={callToActionUri}
        idName={gradientId}
        key={`cat${callToActionUri}`}
        gredientStart={gredientStart}
        gradientEnd={gradientEnd}
      >
        {callToActionTitle}
        <SvgWrapper>
          <defs>
            <linearGradient id={gradientId}>
              <stop offset="0" style={{ stopColor: gredientStart }} />
              <stop offset="1" style={{ stopColor: gradientEnd }} />
            </linearGradient>
          </defs>
          <SvgRect x="0.5" y="0.5" rx="20" ry="20" width="100%" height="100%" idName={gradientId} />
        </SvgWrapper>
      </CallToAction>
    );
  }, [link, gradient]);

  return (
    <Wrapper ref={ref}>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MainContent>
                <Title
                  bold
                  offset={getKey(titleSettings, 'offset')}
                  columns={getKey(titleSettings, 'columns')}
                >
                  {title}
                </Title>
                <Content
                  offset={getKey(contentSettings, 'offset')}
                  columns={getKey(contentSettings, 'columns')}
                >
                  {content}
                  {callToAction}
                </Content>
              </MainContent>
            </div>
          </div>
        </div>
      </ContentWrapper>
      <ImageFluidWrapper>
        <PlaceholderMobile fluid={placeholderMobile} objectPosition="top center" />
        <PlaceholderDesktop fluid={backgroundImage} />
      </ImageFluidWrapper>
    </Wrapper>
  );
};

ContentCard.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  content: PropTypes.string,
  contentSettings: PropTypes.shape({
    columns: PropTypes.number,
    offset: PropTypes.number
  }),
  gradient: PropTypes.object,
  index: PropTypes.number,
  link: PropTypes.object,
  title: PropTypes.string,
  titleSettings: PropTypes.shape({
    columns: PropTypes.number,
    offset: PropTypes.number
  })
};

export default ContentCard;
